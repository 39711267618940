import { createSelector } from 'reselect';

export const getScrollState = createSelector([state => state], state => ({
  searchAndBrowse: state.searchAndBrowse,
  entities: state.entities,
  taxonomy: state.taxonomy,
  content: state.content,
  page: state.page,
}));

export const getScrolledLineNumber = (state: WtrState) => state.scrollSessionStorage?.lineNumber;
