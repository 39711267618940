import { POD_SLIDEDOWN_MULTIBUY_NUDGE } from 'constants/monetateExperiments';

export const getShowSlidedownMultibuyNudge = ({
  currentPathname,
  getDecisionById,
  multibuyPromotionPath,
}) => {
  if (!multibuyPromotionPath || currentPathname.includes(multibuyPromotionPath)) {
    return { showMultibuyNudge: false };
  }

  const { variant, trackExperimentView } = getDecisionById(
    POD_SLIDEDOWN_MULTIBUY_NUDGE.experiment,
    {
      manualTracking: true,
    },
  );

  let variantCopy = null;

  switch (variant) {
    case POD_SLIDEDOWN_MULTIBUY_NUDGE.variant.showLinkBuild:
      variantCopy = 'Build your offer';
      break;

    case POD_SLIDEDOWN_MULTIBUY_NUDGE.variant.showLinkComplete:
      variantCopy = 'Complete offer';
      break;

    case POD_SLIDEDOWN_MULTIBUY_NUDGE.variant.showLinkExplore:
      variantCopy = 'Explore this offer';
      break;

    default:
      variantCopy = null;
  }

  return {
    showMultibuyNudge: variant !== POD_SLIDEDOWN_MULTIBUY_NUDGE.variant.default,
    trackExperimentView,
    variantCopy,
  };
};
