import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from 'react';
import cqResponsivePropType from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/data-shapes/cq-responsive';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMarketingBadge } from 'hooks/use-marketing-badge';

import servingInstructions from 'constants/servingInstructions';
import { builderGrid } from 'components/Builder/helpers';

import { conflictType } from 'components/Conflict/propTypes';
import { ENTERTAINING } from 'constants/productTypes';
import { clearTimeout, setTimeout } from 'utils/settimeout-wrapper';
import { getPDPUrl, isPersonalisableProduct } from 'utils/product';

import ProductPodCounter from 'components/ProductPod/ProductPodCounter/ProductPodCounter';
import DeleteFromList from 'components/ProductPod/DeleteFromList';
import Favourite from 'components/Product/Favourite/Favourite';
import ProductBadge from 'components/Product/Badge';

// TODO: [SSR][WARN] Does this impact SSR?
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointCurrent } from 'utils/mediaQueries';

import { useExperiments } from 'components/Experiment/useExperiments';
import { ONE_POD_CQRESPONSIVE } from 'constants/podSizing';
import { dataLayer } from 'analytics/data-layer';
import { usePodImpression } from 'hooks/use-pod-impression';
import { usePodClickTracking } from 'hooks/use-pod-click-tracking';
import { makePodDataset } from 'components/ProductPod2/utils';
import { getFeatureFlags } from 'utils/feature-flags';
import { isWebKit } from 'utils/is-webkit';

import { BREAKPOINTS } from 'constants/grid';
import { SSRMediaQuery } from 'components/SSRMediaQuery';
import { useWtrSelector } from 'redux/hooks';
import { getScrollState } from 'redux/modules/scroll-session-storage/selectors';
import { setScrollStateInSessionStorage } from 'utils/scroll-session-storage';
import { getShowSlidedownAdvert } from './getShowSlidedownAdvert';
import ProductPodAttributes from './Attributes';
import ProductPodHeader from './Header';
import ProductPodImage from './Image';
import TrolleyControls from './TrolleyControls';
import Slidedown from './Slidedown';
import SlidedownContent from './SlidedownContent';

import styles from './ProductPod.scss';
import { getShowSlidedownMultibuyNudge } from './getShowSlidedownMultibuyNudge';

const ProductPod = ({
  lineNumber,
  addRemoveFavourite,
  attributes,
  conflict,
  cookingStatus,
  className,
  cqResponsive,
  fullSize,
  hideUneditable,
  isEditable,
  isFavourite,
  isBuilderPage,
  listId,
  location,
  isInMealDeal,
  orderId,
  positions,
  searchType,
  sponsored = false,
  toggleListMembership,
  optimisticTrolleyQuantityAmount,
  optimisticTrolleyQuantityUom,
  isDisabled,
  wrapperId,
  onSlidedownToggle,
  slidedownAdvertDetails,
  multibuyPromotionPath,
  depositCharge,
  displayPriceEstimated,
  displayPriceQualifier,
  productId,
  productName,
  productType,
  thumbnail,
  marketingBadges,
  maxPersonalisedMessageLength,
  sponsorshipId,
  metadata,
  hasPromotions,
  crealytics,
}) => {
  const isPersonalisable = isPersonalisableProduct({ maxPersonalisedMessageLength });

  const observeRef = usePodImpression(
    { sponsorshipId, metadata, trackingUrls: crealytics?.beaconUrls },
    sponsored,
  );

  const { deviceBreakpoints = [] } = cqResponsive || ONE_POD_CQRESPONSIVE;

  const { getDecisionById } = useExperiments();

  const timeoutId = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [added, setIsAdded] = useState(false);

  const currentPathname = location.pathname;
  const active = optimisticTrolleyQuantityAmount > 0;
  const isEntertaining = productType === ENTERTAINING;

  const { showAdvert, trackExperimentView: trackAdvertExperimentView } = getShowSlidedownAdvert({
    currentPathname,
    getDecisionById,
    slidedownAdvertDetails,
  });

  const {
    showMultibuyNudge,
    trackExperimentView: trackMultibuyExperimentView,
    variantCopy,
  } = getShowSlidedownMultibuyNudge({
    currentPathname,
    getDecisionById,
    multibuyPromotionPath,
  });

  const displaySlidedown = showAdvert || showMultibuyNudge;

  const wrappedSetIsOpen = useCallback(
    value => {
      setIsOpen(value);
      onSlidedownToggle?.(value);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = undefined;
      }
    },
    [onSlidedownToggle],
  );

  useEffect(() => {
    if (fullSize) {
      const closeSlidedown = () => wrappedSetIsOpen(false);

      document.addEventListener('slickSlideChanged', closeSlidedown);

      return () => {
        document.removeEventListener('slickSlideChanged', closeSlidedown);
      };
    }

    return undefined;
  }, [fullSize, wrappedSetIsOpen]);

  useEffect(() => {
    if (optimisticTrolleyQuantityAmount === 0) {
      wrappedSetIsOpen(false);
      setIsAdded(false);
    }
  }, [optimisticTrolleyQuantityAmount, wrappedSetIsOpen]);

  useEffect(() => {
    if (!added || showAdvert) {
      return () => {};
    }

    if (showMultibuyNudge) {
      timeoutId.current = setTimeout(() => wrappedSetIsOpen(false), 6000);

      return () => {
        clearTimeout(timeoutId.current);
        timeoutId.current = undefined;
      };
    }

    return () => {};
  }, [added, wrappedSetIsOpen, showAdvert, showMultibuyNudge]);

  const productPosition = positions[mediaBreakpointCurrent()] ?? undefined;

  const { showMarketingBadges, marketingBadgesFiltered } = useMarketingBadge(marketingBadges);

  const { reportClickToAdTech, reportAnalyticsClick } = usePodClickTracking({
    conflictMessage: conflict?.messages?.shortItem?.toLowerCase(),
    isFavourite,
    position: productPosition,
    productId,
    searchType,
    wrapperId,
    boosted: false,
    sponsored,
  });

  const { build_cwvContentVisibility: contentVisibilityFlag } = getFeatureFlags();
  const applyContentVisibility = contentVisibilityFlag && !isWebKit();

  const displayClasses = useMemo(() => {
    const cqSizing = deviceBreakpoints
      .map(({ name, visible } = {}) =>
        visible ? `visible-${name === 'default' ? 'xl' : name}-flex` : null,
      )
      .filter(breakpoint => breakpoint)
      .join(' ');
    const onePodSizing = [styles.productPod]
      .concat(
        deviceBreakpoints
          .map(
            ({ name, width } = {}) =>
              `col-${name === 'default' ? 'xl' : name}-${
                isBuilderPage ? builderGrid[name] : width
              }of12`,
          )
          .filter(breakpoint => breakpoint),
      )
      .join(' ');

    return classNames(
      {
        [cqSizing]: deviceBreakpoints.length > 0,
        [onePodSizing]: !fullSize,
        [styles.fullSize]: fullSize,
        [styles.noDeviceBreakpoints]: deviceBreakpoints.length < 1,
      },
      className,
    );
  }, [className, isBuilderPage, deviceBreakpoints, fullSize]);
  const toggleOptions = () => wrappedSetIsOpen(!isOpen);

  const toggleFavourite = useCallback(() => {
    if (!isFavourite) {
      reportClickToAdTech();
    }
    addRemoveFavourite(isFavourite, lineNumber);
  }, [addRemoveFavourite, isFavourite, lineNumber, reportClickToAdTech]);

  const removeFromList = () => {
    dataLayer.push({
      event: 'list-delete-button-click',
      lineNumber,
      listId,
    });
    toggleListMembership({
      lineNumber,
      shoppingListId: listId,
    });
  };

  const setAdded = useCallback(
    isAdded => {
      setIsAdded(isAdded);
      reportClickToAdTech();

      if (!isAdded) {
        return;
      }

      if (showAdvert) {
        wrappedSetIsOpen(true);
        trackAdvertExperimentView(true);
      }

      if (showMultibuyNudge) {
        wrappedSetIsOpen(true);
        trackMultibuyExperimentView(true);
      }
    },
    [
      reportClickToAdTech,
      showAdvert,
      showMultibuyNudge,
      trackAdvertExperimentView,
      trackMultibuyExperimentView,
      wrappedSetIsOpen,
    ],
  );

  const scrollState = useWtrSelector(getScrollState);
  const onProductClick = useCallback(() => {
    setScrollStateInSessionStorage(scrollState, lineNumber);
    reportAnalyticsClick();
  }, [scrollState, lineNumber, reportAnalyticsClick]);

  const productPodAttributesProps = useMemo(
    () => ({
      attributes,
      cookingStatus,
      entertaining: isEntertaining,
      fullSize,
      productName,
      productId,
      productType,
      searchType,
      reportAnalyticsClick,
    }),
    [
      attributes,
      cookingStatus,
      fullSize,
      isEntertaining,
      productId,
      productName,
      productType,
      reportAnalyticsClick,
      searchType,
    ],
  );

  if (hideUneditable && !isEditable) return null;

  const productUrl = productId ? getPDPUrl(productId, productName) : '';

  const sponsoredOrRegular = sponsored ? 'sponsored' : 'regular';
  const podType = fullSize ? 'click-to-buy' : sponsoredOrRegular;

  return (
    <article
      data-testid="product-pod"
      {...makePodDataset({
        hasPromotions,
        lineNumber,
        name: productName,
        productType,
        productPosition,
        conflictMessage: conflict?.messages?.shortItem?.toLowerCase(),
        podType,
      })}
      className={classNames(displayClasses, { [styles.contentVisibility]: applyContentVisibility })}
      id={`tPp${productId}`}
      ref={observeRef}
    >
      <div
        className={classNames(styles.icons, {
          [styles.activeIcons]: active,
          [styles.hasBadge]: sponsored,
        })}
      >
        <div className={classNames(styles.badgeContainer)}>
          {(sponsored || showMarketingBadges) && (
            <ProductBadge text={sponsored ? 'Sponsored' : marketingBadgesFiltered[0].name} />
          )}
          {listId && !showMarketingBadges && <DeleteFromList removeFromList={removeFromList} />}
        </div>
        {listId && showMarketingBadges ? (
          <DeleteFromList removeFromList={removeFromList} />
        ) : (
          <Favourite
            toggleFavourite={toggleFavourite}
            isFavourite={isFavourite}
            lineNumber={lineNumber}
          />
        )}
      </div>
      <div
        className={classNames(styles.content, {
          [styles.isEntertaining]: isEntertaining,
          [styles.inTrolley]: active,
        })}
      >
        <section className={styles.details}>
          <SSRMediaQuery minWidth={BREAKPOINTS.sm} groupId="product-pod-counter-min-sm">
            <div
              className={classNames(styles.desktopCounter, {
                [styles.desktopCounterActive]: active,
                [styles.optionsOpen]: isOpen,
                [styles.inTrolley]: active,
              })}
            >
              <ProductPodCounter
                {...{
                  added,
                  trolleyQuantityAmount: optimisticTrolleyQuantityAmount,
                  trolleyQuantityUom: optimisticTrolleyQuantityUom,
                  productName,
                  isInMealDeal,
                }}
              />
              {displaySlidedown && (
                <Slidedown
                  {...{
                    active,
                    displayToggle: true,
                    isOpen,
                    toggleOptions,
                    wrappedSetIsOpen,
                  }}
                >
                  <SlidedownContent
                    {...{
                      isOpen,
                      multibuyPromotionPath,
                      showAdvert,
                      showMultibuyNudge,
                      slidedownAdvertDetails,
                      variantCopy,
                    }}
                  />
                </Slidedown>
              )}
            </div>
          </SSRMediaQuery>
          <ProductPodImage
            position={productPosition}
            onClick={onProductClick}
            fullSize={fullSize}
            name={productName}
            productId={productId}
            thumbnail={thumbnail}
            disabled={isDisabled}
            isEntertaining={isEntertaining}
          />
          <div className={styles.headerStarWrapper}>
            <ProductPodHeader
              position={productPosition}
              onClick={onProductClick}
              fullSize={fullSize}
              name={productName}
              productId={productId}
            />
            <div
              className={classNames(
                styles.podSectionAttributes,
                'visible-xs-block',
                'visible-sm-block',
                'visible-md-block',
                'visible-lg-block',
                'visible-xl-block',
              )}
              data-test="product-pod-content"
            >
              <ProductPodAttributes {...productPodAttributesProps} />
            </div>
          </div>
        </section>
        <section
          data-testid="product-pod-trolley-choices"
          className={classNames({
            [styles.fullSizeTrolleyControls]: fullSize,
            [styles.trolleyChoices]: !fullSize,
          })}
        >
          <TrolleyControls
            {...{
              productName,
              conflict,
              isPersonalisableProduct: isPersonalisable,
              productUrl,
              productId,
              depositCharge,
              displayPriceEstimated,
              displayPriceQualifier,
              isDisabled,
              orderId,
              setAdded,
              lineNumber,
              productPosition,
              searchType,
            }}
          />
        </section>
        <SSRMediaQuery maxWidth={BREAKPOINTS.sm} groupId="product-pod-counter-max-sm">
          <section
            className={classNames(styles.mobileCounter, styles.optionsOpen, {
              [styles.mobileCounterActive]: active,
              [styles.noSlidedown]: !displaySlidedown,
            })}
          >
            <ProductPodCounter
              {...{
                added,
                trolleyQuantityAmount: optimisticTrolleyQuantityAmount,
                trolleyQuantityUom: optimisticTrolleyQuantityUom,
                productName,
                isInMealDeal,
              }}
            />
            {displaySlidedown && (
              <Slidedown>
                <SlidedownContent
                  {...{
                    isOpen,
                    multibuyPromotionPath,
                    showAdvert,
                    showMultibuyNudge,
                    slidedownAdvertDetails,
                    variantCopy,
                  }}
                />
              </Slidedown>
            )}
          </section>
        </SSRMediaQuery>
      </div>
    </article>
  );
};

ProductPod.displayName = 'ProductPod';

ProductPod.propTypes = {
  addRemoveFavourite: PropTypes.func,
  attributes: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  lineNumber: PropTypes.string.isRequired,
  className: PropTypes.string,
  conflict: conflictType,
  cookingStatus: PropTypes.oneOf(Object.keys(servingInstructions)),
  cqResponsive: cqResponsivePropType(),
  fullSize: PropTypes.bool,
  hideUneditable: PropTypes.bool,
  isBuilderPage: PropTypes.bool,
  isEditable: PropTypes.bool,
  isFavourite: PropTypes.bool,
  isPersonalisableProduct: PropTypes.bool,
  listId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  isInMealDeal: PropTypes.bool,
  orderId: PropTypes.string,
  promoDescription: PropTypes.string,
  positions: PropTypes.shape({
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xl: PropTypes.number,
    xs: PropTypes.number,
  }),

  metadata: PropTypes.shape({
    recToken: PropTypes.string,
    monetateId: PropTypes.string,
  }),
  maxPersonalisedMessageLength: PropTypes.number,
  sponsorshipId: PropTypes.string,
  hasPromotions: PropTypes.bool,
  searchType: PropTypes.string,
  sponsored: PropTypes.bool,
  taxonomyLevel: PropTypes.string,
  toggleListMembership: PropTypes.func,
  optimisticTrolleyQuantityAmount: PropTypes.number.isRequired,
  optimisticTrolleyQuantityUom: PropTypes.string.isRequired,
  wrapperId: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  index: PropTypes.number,
  onSlidedownToggle: PropTypes.func,
  slidedownAdvertDetails: PropTypes.shape({
    experimentKey: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  multibuyPromotionPath: PropTypes.string,
  depositCharge: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  displayPriceEstimated: PropTypes.bool.isRequired,
  displayPriceQualifier: PropTypes.string,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  marketingBadges: PropTypes.arrayOf(PropTypes.shape({})),
  crealytics: PropTypes.shape({
    beaconUrls: PropTypes.arrayOf(PropTypes.string),
    clickUrls: PropTypes.arrayOf(PropTypes.string),
  }),
};

ProductPod.defaultProps = {
  addRemoveFavourite: () => {},
  attributes: {},
  className: '',
  conflict: null,
  cookingStatus: undefined,
  cqResponsive: ONE_POD_CQRESPONSIVE,
  depositCharge: null,
  displayPriceQualifier: undefined,
  fullSize: false,
  hideUneditable: false,
  isBuilderPage: false,
  isEditable: true,
  isFavourite: false,
  isPersonalisableProduct: false,
  listId: undefined,
  location: {},
  isInMealDeal: false,
  metadata: {},
  maxPersonalisedMessageLength: 0,
  sponsorshipId: undefined,
  hasPromotions: false,
  orderId: undefined,
  promoDescription: '',
  positions: {},
  searchType: '',
  sponsored: false,
  taxonomyLevel: '',
  toggleListMembership: () => {},
  wrapperId: '',
  index: -1,
  onSlidedownToggle: undefined,
  slidedownAdvertDetails: undefined,
  multibuyPromotionPath: undefined,
  marketingBadges: [],
  crealytics: undefined,
};

ProductPod.contextTypes = {
  router: PropTypes.shape({ history: PropTypes.shape({ push: PropTypes.func }) }),
};

export default memo(ProductPod);
