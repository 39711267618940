import { MutableRefObject } from 'react';
import { useOnClickOutside } from 'hooks';

type Props = {
  insideRef: MutableRefObject<HTMLDivElement | undefined>;
  onClick: () => void;
};

function OnClickOutside({ insideRef, onClick }: Props) {
  useOnClickOutside(insideRef, () => {
    onClick();
  });
  return null;
}

export default OnClickOutside;
