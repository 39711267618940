import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { LazyIcon } from 'components/LazyIcon/LazyIcon';
import styles from './Advert.scss';

const Advert = ({ active, imageUrl, logoUrl, iconName, text, url }) => {
  const image = imageUrl ?? logoUrl;

  return (
    <Link
      className={classnames(styles.wrapper, {
        [styles.active]: active,
        [styles.imageWrapper]: !!imageUrl,
        [styles.logoWrapper]: !!logoUrl && !imageUrl,
      })}
      to={{ pathname: url }}
    >
      {image ? (
        <img className={styles.image} src={image} alt="" loading="lazy" />
      ) : (
        <i className={styles.icon}>
          <LazyIcon legacy={false} name={iconName} />
        </i>
      )}
      <div className={styles.linkWrapper}>
        <Typography styleAs="paragraphSmall" title={text} className={styles.link} noMargins>
          {text}
        </Typography>
      </div>
    </Link>
  );
};

Advert.propTypes = {
  active: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  iconName: PropTypes.string,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Advert.defaultProps = {
  imageUrl: undefined,
  logoUrl: undefined,
  iconName: 'Success',
};

export default Advert;
