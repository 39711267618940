import env from 'env/env';
import { MONETATE_DEFAULT_VARIANT } from 'constants/monetate';

export const getShowSlidedownAdvert = ({
  currentPathname,
  getDecisionById,
  slidedownAdvertDetails,
}) => {
  if (!slidedownAdvertDetails || currentPathname.includes('beforeYouGo')) {
    return { showAdvert: false };
  }

  try {
    const { pathname } = new URL(slidedownAdvertDetails.url, env.clientHost);

    if (currentPathname === pathname) {
      return { showAdvert: false };
    }
  } catch (_) {
    return { showAdvert: false };
  }

  const { variant, trackExperimentView } = getDecisionById(slidedownAdvertDetails.experimentKey, {
    manualTracking: true,
  });

  return { showAdvert: variant !== MONETATE_DEFAULT_VARIANT, trackExperimentView };
};
