import React from 'react';
import PropTypes from 'prop-types';
import MultibuyNudge from './MultibuyNudge';
import Advert from './Advert';

const SlidedownContent = ({
  isOpen,
  multibuyPromotionPath,
  showAdvert,
  showMultibuyNudge,
  slidedownAdvertDetails,
  variantCopy,
}) => {
  if (showAdvert) {
    return <Advert active={isOpen} {...slidedownAdvertDetails} />;
  }

  if (showMultibuyNudge) {
    return (
      <MultibuyNudge multibuyPromotionPath={multibuyPromotionPath} variantCopy={variantCopy} />
    );
  }

  return null;
};

SlidedownContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  multibuyPromotionPath: PropTypes.string,
  showAdvert: PropTypes.bool,
  showMultibuyNudge: PropTypes.bool,
  slidedownAdvertDetails: PropTypes.shape({
    imageUrl: PropTypes.string,
    logoUrl: PropTypes.string,
    iconName: PropTypes.string,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  variantCopy: PropTypes.string,
};

SlidedownContent.defaultProps = {
  multibuyPromotionPath: undefined,
  showAdvert: undefined,
  showMultibuyNudge: undefined,
  slidedownAdvertDetails: undefined,
  variantCopy: null,
};

export default SlidedownContent;
