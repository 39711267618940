import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronDownBold } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import styles from './Slidedown.scss';

const Slidedown = ({
  active,
  children,
  displayToggle,
  isOpen,
  toggleOptions,
  wrappedSetIsOpen,
}) => {
  const handleInteraction = () => {
    if (active) {
      wrappedSetIsOpen(true);
    }
  };

  return (
    <>
      {displayToggle && (
        <button
          aria-label={isOpen ? 'Close options' : 'Open options'}
          className={styles.optionsIcon}
          type="button"
          onClick={toggleOptions}
          data-testid="slidedown-options-toggle"
          tabIndex={active ? '0' : '-1'}
        >
          <ChevronDownBold
            className={classNames(styles.icon, {
              [styles.openIcon]: isOpen,
            })}
          />
        </button>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(styles.optionsWrapper, {
          [styles.openOptions]: isOpen,
        })}
        aria-live="polite"
        onMouseEnter={handleInteraction}
        onClick={handleInteraction}
        onKeyDown={handleInteraction}
        data-testid="slidedown-options-wrapper"
      >
        {children}
      </div>
    </>
  );
};

Slidedown.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  displayToggle: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleOptions: PropTypes.func,
  wrappedSetIsOpen: PropTypes.func,
};

Slidedown.defaultProps = {
  active: false,
  displayToggle: false,
  isOpen: false,
  toggleOptions: () => {},
  wrappedSetIsOpen: () => {},
};

export default Slidedown;
