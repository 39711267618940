import { useRef, useEffect } from 'react';
import { setTimeout, clearTimeout } from 'utils/settimeout-wrapper';

export default function useSupercedingTimer() {
  const delayedAction = useRef(null);

  const clearSupercedingTimeout = () => {
    if (delayedAction.current) {
      clearTimeout(delayedAction.current);
      delayedAction.current = null;
    }
  };

  const updateTimeout = (cb, timeout) => {
    if (delayedAction.current) {
      clearTimeout(delayedAction.current);
    }
    delayedAction.current = setTimeout(cb, timeout);
  };

  // The return value of the effect is a function, so it will be called only on unmount
  useEffect(() => clearSupercedingTimeout, []);

  return { updateTimeout, clearSupercedingTimeout };
}
