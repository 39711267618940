import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { dataAttributesGenerator } from 'utils/data-attribute-generator';

import ProductSize from 'components/Product/Size';
import ProductTags from 'components/ProductTags';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { getPDPUrl } from 'utils/product';

import styles from './index.scss';

const ProductPodHeader = ({ fullSize, name, productId, onClick }) => {
  if (!productId) return null;

  return (
    <header
      data-testid="product-pod-header"
      className={classNames(styles.podHeader, {
        [styles.fullSize]: fullSize,
      })}
    >
      <TextLink
        underline="hover"
        href={getPDPUrl(productId, name)}
        {...dataAttributesGenerator({
          actiontype: 'redirect',
          originComponent: 'ProductPod',
          shortDescription: `view product details for ${name}`,
        })}
        onClick={onClick}
        className={styles.nameLink}
      >
        <h2 className={classNames(styles.nameWrapper)} data-testid="product-pod-name">
          <span className={classNames(styles.name, styles.ellipses)} title={name}>
            {name}
          </span>
        </h2>
        <ProductSize additionalClasses={styles.sizeMessage} productId={productId} />
      </TextLink>
      <ProductTags productId={productId} />
    </header>
  );
};

ProductPodHeader.displayName = 'ProductPodHeader';

ProductPodHeader.propTypes = {
  badgeText: PropTypes.string,
  entertaining: PropTypes.bool,
  fullSize: PropTypes.bool,
  name: PropTypes.string,
  productId: PropTypes.string,
  onClick: PropTypes.func,
  searchType: PropTypes.string,
  position: PropTypes.number,
};

ProductPodHeader.defaultProps = {
  badgeText: undefined,
  entertaining: false,
  fullSize: false,
  name: undefined,
  productId: undefined,
  onClick: () => {},
  searchType: '',
  position: undefined,
};

export default memo(ProductPodHeader);
